import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.back, "title": "Edit Cluster Group", "width": 520 }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.onCancelClick } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": _vm.isLoading, "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.onSaveClick);
      } } }, [_vm._v(" Move ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_vm.editForm ? _c("a-row", { attrs: { "gutter": 24 } }, [_c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("text-input", { attrs: { "form-item": "", "label": "Group Channel", "value": _vm.groupChannel, "max-length": 8, "rules": "required", "disabled": true }, on: { "change": function($event) {
      return _vm.storeValue("groupChannel", $event);
    } } })], 1), _c("a-col", { staticClass: "pb-3", attrs: { "span": 12 } }, [_c("select-input", { attrs: { "form-item": "", "label": "Cluster Group", "placeholder": _vm.editForm.clusterGroup, "data-source": _vm.clusterGroups, "source": "id", "source-label": "name", "rules": "required" }, on: { "change": _vm.changeClusterGroup } })], 1)], 1) : _vm._e()], 1)];
  } }]) });
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditClusterGroupMappingL1",
  inject: ["resourceProps"],
  data() {
    const [clusterProps, clusterGroupProps] = this.resourceProps;
    const { apiUrl: apiUrl2 } = clusterProps;
    return {
      apiUrl: apiUrl2,
      clusterProps,
      clusterGroupProps,
      visible: true,
      isLoading: false,
      editForm: {}
    };
  },
  computed: {
    groupChannel() {
      const { groupChannel, groupChannelDesc } = this.editForm;
      return `${groupChannel} - ${groupChannelDesc.replace(/\s{2,}/g, " ")}`;
    },
    clusterGroups() {
      return this.clusterGroupProps.crud.getList().filter((group) => this.editForm.clusterGroup !== group.name);
    }
  },
  created() {
    const { id } = this.$route.params;
    const entity = this.clusterProps.crud.getEntity(id);
    if (!entity)
      this.back();
    this.editForm = entity;
    this.storeValues();
    this.clusterGroupProps.crud.fetchList();
  },
  methods: {
    storeValues() {
      Object.entries(this.editForm).forEach(([resourceKey, value]) => this.storeValue(resourceKey, value));
    },
    storeValue(resourceKey, value) {
      this.editForm = {
        ...this.editForm,
        [resourceKey]: value
      };
      this.clusterProps.crud.updateEntity("editForm", { resourceKey, value });
    },
    genGroupChannelLabel(option) {
      return `${option.groupChannel} - ${option.groupChannelDesc}`;
    },
    async onSaveClick() {
      this.isLoading = true;
      await this.clusterProps.crud.submitEntity("replace");
      this.isLoading = false;
      this.visible = false;
    },
    changeClusterGroup(value) {
      this.storeValue("clusterGroupId", value);
    },
    onCancelClick() {
      this.visible = false;
    },
    back() {
      this.$router.push(this.clusterProps.redirectRoute);
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditClusterGroupMappingL1 = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "edit-cluster-group-mappingL1" }, [_c("resource", { attrs: { "api-url": _vm.apiUrl, "name": [
    "web-analytics.cluster-l1-trans",
    "web-analytics.common.cluster-groups"
  ], "resource-id-name": ["id", "id"], "redirect-route": ["/web-analytics/cluster-l1-trans"] } }, [_c("edit-cluster-group-mapping-l1")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    EditClusterGroupMappingL1
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
